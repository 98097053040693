@import '../app/variables.less';

.registration-layout {
    min-height      : 100vh;
    background-color: @registration-bg-color;

    .registration-content {
        .inner-layout {
            background-color: @registration-bg-color;
            padding         : 24px 0;

            @media @phone {
                padding: 0;
            }

            .inner-content {
                padding   : 0 24px;
                min-height: 280px;

                @media @phone {
                    padding: 0 5px;
                }
            }
        }
    }

    .registration-footer {
        color     : #fff;
        background: @primary-color;
        text-align: center;
    }

    .registration-header {
        display        : flex;
        justify-content: start;
        padding        : 5px 15px;
        text-transform : uppercase;
        margin-bottom  : 10px;
        border-bottom  : 2px solid;
        background     : @registration-bg-color;
        color          : @primary-color;
    }

    .single-step {
        background: @primary-color;

        .ant-steps-item-container {
            margin-top: 10px;
        }

        .ant-steps-item-title,
        .ant-steps-item-description,
        .ant-steps-icon {
            color: #fff !important;
        }
    }
}

.badge-buttons {
    display        : flex;
    justify-content: space-evenly;
    margin-bottom  : 10px;

    .btn {
        box-sizing                 : border-box;
        display                    : inline-block;
        text-align                 : left;
        white-space                : nowrap;
        text-decoration            : none;
        vertical-align             : middle;
        touch-action               : manipulation;
        cursor                     : pointer;
        user-select                : none;
        border                     : 1px solid @primary-color;
        padding                    : 4px 8px;
        border-radius              : 4px;
        color                      : @primary-color;
        fill                       : @primary-color;
        background                 : @registration-bg-color;
        line-height                : 1em;
        min-width                  : 150px;
        height                     : 45px;
        transition                 : .2s ease-out;
        box-shadow                 : 0 1px 2px rgba(@registration-bg-color, 20%);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-family                : system, -apple-system, '.SFNSDisplay-Regular', 'San Francisco', 'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;
        font-weight                : 500;
        text-rendering             : optimizeLegibility;
        //
        // Controversial! But prevents text flickering in
        // Safari/Firefox when animations are running
        //
        -webkit-font-smoothing    : antialiased;
        -moz-osx-font-smoothing   : grayscale;
        -moz-font-feature-settings: 'liga', 'kern';

        &:hover,
        &:focus {
            background  : @primary-color;
            color       : @registration-bg-color;
            fill        : @registration-bg-color;
            border-color: @primary-color;
            transform   : scale(1.01) translate3d(0, -1px, 0);
            box-shadow  : 0 4px 8px rgba(@registration-bg-color, 20%);

            .ant-typography {
                color: @registration-bg-color;
            }
        }

        &:active {
            outline   : 0;
            background: lighten(@primary-color, 15%);
            transition: none;
        }

        svg {
            display       : inline-block;
            vertical-align: top;
            width         : 30px;
            height        : 30px;
            margin-right  : 5px;
            margin-top    : 5px;
        }

        .ant-typography {
            color: @primary-color;
        }

        .btn-text,
        .btn-storename {
            display       : inline-block;
            vertical-align: top;
        }

        .btn-text {
            letter-spacing: .08em;
            margin-top    : -.1em;
            font-size     : 10px;
        }

        .btn-storename {
            display       : block;
            margin-left   : 32px;
            margin-top    : -20px;
            font-size     : 22px;
            letter-spacing: -.03em;
        }
    }
}

.top-text {
    text-align   : justify;
    margin-bottom: 10px;
}

.bottom-text {
    text-align: justify;
}

/*********************************************************************/
/*                      AJUSTES PARA MOBILE                          */
/*********************************************************************/

@media @phone {
    .registration-layout {
        .registration-content {
            .inner-layout {
                padding: 0;

                .inner-content {
                    padding: 0 5px;
                }
            }
        }

        .registration-footer {
            display: none;
        }
    }

    .badge-buttons {
        justify-content: space-around;

        .btn {
            padding      : 3px;
            height       : 30px;
            min-width    : 0;
            border-radius: 3px;

            svg {
                width : 14px;
                height: 14px;
            }


            .btn-text {
                font-size: 8px;
            }

            .btn-storename {
                margin-left: 17px;
                margin-top : -10px;
                font-size  : 14px;
            }
        }
    }

    .bottom-text {
        text-align: center;
    }
}
@font-family: 'Poppins', sans-serif;@primary-color: #7909ad;@secondary-color: #4d4d4d;@info-color: #1aa2c4;@success-color: #5ab37b;@processing-color: #1aa2c4;@error-color: #c41a1a;@highlight-color: #c41a1a;@warning-color: #c4ab1a;@normal-color: #fff;@font-size-base: 12px;@font-size-lg: 14px;@white: #ffffff;@black: #494949;@gray: #8e8e8e;@light-gray: #ececec;@border-color-base: @primary-color;@tooltip-bg: #7909ad;@menu-bg: #fff;@text-selection-bg: #7909ad;@checkbox-color: @primary-color;@status-color: [object Object];@layout-body-background: #F1F1F1;@layout-header-background: #fff;@layout-footer-background: @layout-body-background;@layout-header-height: 72px;@layout-sider-background: @layout-header-background;@input-bg: #F5F5F5;@input-hover-border-color: @primary-color;@btn-primary-bg: @primary-color;@primary6: @primary-color;@link-color: @primary-color;@font-sizes: [object Object];@spaces: .2rem,.5rem,.8rem,1rem,1.2rem,1.5rem,2rem,2.4rem,2.7rem,3rem,3.4rem,3.5rem,4rem;